<template>
  <div
    :class="['modal fade show', classModalGlobal]"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: block"
  >
    <div :class="['modal-dialog modal-dialog-centered', classModal]" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="hasHeader">
          <slot name="modalTitle" v-if="hasTitle">
            <h5 class="modal-title">&nbsp;</h5>
          </slot>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="modalBody">Contenu du modal</slot>
        </div>
        <div class="modal-footer" v-if="hasFooter">
          <button
            type="button"
            v-if="hasCloseBtn"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="$emit('close')"
          >
            <slot name="closeBtn">Fermer</slot>
          </button>
          <button
            type="button"
            v-if="hasCancelBtn"
            class="btn btn-secondary"
            @click="$emit('cancel')"
          >
            <slot name="cancelBtn">Refuser</slot>
          </button>
          <button
            type="button"
            v-if="hasConfirmBtn"
            class="btn btn-primary"
            @click="$emit('confirm')"
          >
            <slot name="confirmationBtn">Confirmer</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>

<script>
export default {
  emits: ["cancel", "confirm", "close"],
  props: {
    classModalGlobal: {
      type: String,
      default: null,
    },
    classModal: {
      type: String,
      default: null,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasConfirmBtn: {
      type: Boolean,
      default: true,
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    hasCancelBtn: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
