<template>
  <div :class="[validated && errors ? 'field-error' : 'row flex-1']">
    <label class="form-label" v-if="label"> {{ label }}</label>

    <div v-if="validated && errors" class="error-block">
      <span class="error-message">{{ errors }}</span>
    </div>
    <div class="col">
      <v-select
        :options="typesOptions"
        :model-value="modelValue.type"
        @update:modelValue="v => changeValue(v, 'type')"
      >
        <template v-slot:no-options>Aucune option disponible</template>
      </v-select>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">
          <div class="field-block">
            <input
              v-if="modelValue?.type"
              type="text"
              :value="modelValue.input1"
              @change="v => changeValue(v, 'input1')"
              @input="v => changeValue(v, 'input1')"
            />
          </div>
        </div>
        <div class="col-0">
          <div class="flexbox alignCenter fullHeight">
            <label v-if="modelValue?.type === 'Compris entre'">et</label>
          </div>
        </div>
        <div class="col">
          <div class="field-block">
            <input
              v-if="modelValue?.type === 'Compris entre'"
              type="text"
              :value="modelValue.input2"
              :min="modelValue.input1"
              @change="v => changeValue(v, 'input2')"
              @input="v => changeValue(v, 'input2')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
import CustomInputType from "../../components/customize/CustomInputType.vue";
import vSelect from "vue-select";
import { isNaN } from "lodash";

export default {
  emits: ["update:modelValue", "change"],
  components: {
    CustomInputType,
    vSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
    min: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object,
      default: { type: null, input1: null, input2: null },
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
    validations() {
      return {
        input: {
          required: "Merci de renseigner un nombre",
          numeric: "Merci de renseigner un nombre",
        },
      };
    },
    typesOptions() {
      let demandes = [
        { value: "inferieur", label: "Inférieur ou égal à" },
        { value: "superieur", label: "Supérieur à" },
        { value: "compris_entre", label: "Compris entre" },
      ];
      return demandes || [];
    },
  },

  methods: {
    changeValue(value, key) {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        [key]:
          key === "input1" || key === "input2"
            ? isNaN(parseInt(value.target.value, 10))
              ? null
              : parseInt(value.target.value, 10)
            : value?.label,
      });
      this.$emit("change");
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped>
.field-comparator {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0;
}
.field-comparator .date {
  margin: 0;
  margin-right: 10px;
}
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}
</style>
