<template>
  <tr v-for="(item, index) in items" :key="index">
    <td>
      <input v-if="item.editable" v-model="item.field1" />
      <span v-else
        >{{ demande.nom_commune }}
        {{
          demande?.communaute?.nom_commune ? "(" + demande?.communaute?.nom_commune + ")" : null
        }}</span
      >
    </td>
    <td>{{ demande && demande?.date_creation && getDate(demande?.date_creation) }}</td>
    <td>{{ getDate(demande?.communaute ? demande?.communaute?.date_rdv : demande.date_rdv) }}</td>
    <td>
      <input v-if="item.editable" v-model="item.field2" />
      <span v-else>{{ demande?.communaute ? demande?.communaute?.rds_nom : demande.rds_nom }}</span>
    </td>
    <td>
      <input v-if="item.editable" v-model="item.field3" />
      <span v-else>{{ demande?.communaute ? demande?.communaute?.secteur : demande.secteur }}</span>
    </td>
    <td>
      {{ demande?.communaute ? demande?.communaute?.snow_reference : demande.snow_reference }}
    </td>
    <td v-if="actif">
      {{ demande_statut }}
    </td>
    <td v-if="actif">
      {{
        demande?.communaute
          ? demande.communaute?.date_fin_convention &&
            $moment(demande.communaute?.date_fin_convention).format("DD/MM/YYYY")
          : demande.date_fin_convention && $moment(demande.date_fin_convention).format("DD/MM/YYYY")
      }}
    </td>
    <td v-else>{{ demande.motif_abandon }}</td>
    <td>
      <div class="btn-group">
        <router-link
          class="btn tooltip-btn"
          :to="{ name: 'Departements.Commune.Workflow', params: { id: demande.id_demande } }"
        >
          <i class="fal fa-search"></i>
          <span class="tooltip-text">Visualiser</span>
        </router-link>

        <button v-if="isUserLogged" class="btn tooltip-btn" @click="activerModif(index)">
          <i class="fal fa-pen"></i>
          <span class="tooltip-text">Modifier</span>
        </button>

        <button
          v-if="isUserLogged & modifActif"
          class="btn tooltip-btn"
          @click="modifierCommune(demande, index)"
        >
          <i class="fal fa-floppy-disk"></i>
          <span class="tooltip-text">Valider modifications</span>
        </button>

        <button
          class="btn tooltip-btn"
          @click="$emit('delete', demande.id_demande)"
          v-if="isUserLogged"
        >
          <i class="fal fa-trash-alt"></i>
          <span class="tooltip-text">Supprimer</span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DEMANDE_STATUT } from "../../../../../mutuelle-communale-api/app/configs/Properties";
export default {
  data() {
    return {
      modifActif: false,
      items: [
        {
          field1: this.demande.nom_commune,
          field2: this.demande.rds_nom,
          field3: this.demande.secteur,
          editable: false,
        },
      ],
    };
  },
  name: "DemandeDetails",
  emits: ["delete"],
  props: {
    actif: {
      type: Boolean,
      default: true,
    },
    demande: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["isUserLogged"]),
    demande_statut() {
      return DEMANDE_STATUT[
        this.demande?.communaute ? this.demande?.communaute?.statut : this.demande.statut
      ].libelle;
    },
  },
  methods: {
    ...mapActions(["setLoading", "setError", "updateDemande", "getConvention", "getActiveDemande"]),
    getDate(date) {
      if (!date) return;
      return this.$moment(date).format("DD/MM/YYYY");
    },
    async modifierCommune(demande, index) {
      let deja = false;
      if (this.items[index].field1.toUpperCase() === demande.nom_commune.toUpperCase()) {
        deja = true;
      }
      let communeExistante = await this.getActiveDemande(this.items[index].field1.toUpperCase());
      if (
        this.items[index].field1.toUpperCase() === communeExistante.nom_commune &&
        deja === false
      ) {
        this.items[index].field1 =
          "Cette commune existe déja veuillez réessayer avec un nom valide";
        this.modifActif = true;
        this.items[index].editable = true;
      } else if (communeExistante.nom_commune === undefined || deja === true) {
        demande.nom_commune = this.items[index].field1.toUpperCase();
        demande.rds_nom = this.items[index].field2.toUpperCase();
        demande.secteur = this.items[index].field3;
        this.updateDemande({
          id_demande: this.demande.id_demande,
          nom_commune: this.demande.nom_commune,
          rds_nom: this.demande.rds_nom,
          secteur: this.demande.secteur,
        });
        this.items[index].editable = !this.items[index].editable;
        this.modifActif = false;
      }
    },
    activerModif(index) {
      this.items[index].editable = !this.items[index].editable;
      if (this.modifActif) {
        this.modifActif = false;
      } else {
        this.modifActif = true;
      }
    },
  },
};
</script>
