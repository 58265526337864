<template>
  <div>
    <div v-if="demandesListe.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.nom_commune = !sorting.nom_commune;
                    sortListe('nom_commune', sorting.nom_commune);
                  "
                >
                  <span>Commune</span>
                  <i :class="`far  ${sorting.nom_commune ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <custom-input-type
                  type="text"
                  v-model="searching['nom_commune']"
                  @input="filterListe()"
                />
              </div>
            </th>
            <!-- ticket : #7995 -->
            <!-- <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.idgrc = !sorting.idgrc;
                    sortListe('idgrc', sorting.idgrc);
                  "
                >
                  <span>IDGRC</span>
                  <i :class="`far  ${sorting.idgrc ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <custom-input-type type="text" v-model="searching.idgrc" @input="filterListe()" />
            </th> -->
            <th class="intervale">
              <div class="th-title">
                <a
                  @click="
                    sorting.date_creation = !sorting.date_creation;
                    sortListe('date_creation', sorting.date_creation);
                  "
                >
                  <span>Date de création</span>
                  <i :class="`far  ${sorting.date_creation ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <field-date-interval v-model="searching.date_creation" @input="filterListe()" />
              </div>
            </th>
            <th class="intervale">
              <div class="th-title">
                <a
                  @click="
                    sorting.date_rdv = !sorting.date_rdv;
                    sortListe('date_rdv', sorting.date_rdv);
                  "
                >
                  <span>Date du RDV</span>
                  <i :class="`far  ${sorting.date_rdv ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <field-date-interval v-model="searching.date_rdv" @input="filterListe()" />
              </div>
            </th>
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.rds_nom = !sorting.rds_nom;
                    sortListe('rds_nom', sorting.rds_nom);
                  "
                >
                  <span>RDS</span>
                  <i :class="`far  ${sorting.rds_nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <custom-input-type type="text" v-model="searching.rds_nom" @input="filterListe()" />
              </div>
            </th>
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.secteur = !sorting.secteur;
                    sortListe('secteur', sorting.secteur);
                  "
                >
                  <span>Secteur</span>
                  <i :class="`far  ${sorting.secteur ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <custom-input-type type="text" v-model="searching.secteur" @input="filterListe()" />
              </div>
            </th>
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.snow_reference = !sorting.snow_reference;
                    sortListe('snow_reference', sorting.snow_reference);
                  "
                >
                  <span>Réf SNOW</span>
                  <i
                    :class="`far  ${sorting.snow_reference ? 'fa-angle-down' : 'fa-angle-up'}`"
                  ></i>
                </a>
                <custom-input-type
                  type="text"
                  v-model="searching.snow_reference"
                  @input="filterListe()"
                />
              </div>
            </th>
            <th v-if="actif" class="statut">
              <div class="th-title">
                <a
                  @click="
                    sorting.statut = !sorting.statut;
                    sortListe('statut', sorting.statut);
                  "
                >
                  <span>Statut</span>
                  <i :class="`far  ${sorting.statut ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <custom-select-autocomplet
                  class="select-statut"
                  :multiple="true"
                  :options="optionsStatut"
                  v-model.lazy="searching.statut"
                  @change="filterListe()"
                />
              </div>
            </th>
            <th v-if="actif" class="date_fin_convention">
              <div class="th-title">
                <a
                  @click="
                    sorting.date_fin_convention = !sorting.date_fin_convention;
                    sortListe('date_fin_convention', sorting.date_fin_convention);
                  "
                >
                  <span>Expiration convention</span>
                  <i
                    :class="`far  ${sorting.date_fin_convention ? 'fa-angle-down' : 'fa-angle-up'}`"
                  ></i>
                </a>
                <field-date-interval
                  v-model="searching.date_fin_convention"
                  @input="filterListe()"
                />
              </div>
            </th>
            <th v-else>
              <div class="th-title">
                <a
                  @click="
                    sorting.motif = !sorting.motif;
                    sortListe('motif', sorting.motif);
                  "
                >
                  <span>Motif</span>
                  <i :class="`far  ${sorting.motif ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
                <custom-input-type type="text" v-model="searching.motif" @input="filterListe()" />
              </div>
            </th>
            <th>
              <div class="d-flex align-items-center">
                <button class="btn btn-secondary btn-small" @click="resetFilter">
                  <span>
                    <i class="fa-regular fa-refresh" />
                  </span>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <demande-details
            v-for="demande in pageListe"
            :key="demande.id_demande"
            :demande="demande"
            @delete="onDelete"
            :actif="actif"
          />
        </tbody>
      </table>
      <div class="table-footer">
        <div class="pagination-wrapper">
          <button class="btn btn-small btn-primary" @click="onExport">
            <span>EXPORTER</span><i class="fa-solid fa-arrow-right"></i>
          </button>
          <div class="pagination-wrapper">
            <div v-if="nb_pages > 1">
              <div class="pagination">
                <button class="page-number" v-if="current_page > 0" @click="nextPage(current_page)">
                  <i class="far fa-chevron-left" />
                </button>
                <button
                  :class="[i === current_page + 1 && 'active', 'page-number']"
                  v-for="i in nb_pages"
                  :key="i"
                  @click="nextPage(i)"
                >
                  <span>{{ i }}</span>
                </button>
                <button
                  class="page-number"
                  v-if="current_page < nb_pages - 1"
                  @click="nextPage(current_page + 2)"
                >
                  <i class="far fa-chevron-right" />
                </button>
              </div>
            </div>
          </div>
          <custom-basic-select
            class="perpage-selector"
            :options="nbpageOptions"
            v-model="nb_par_page"
          />
        </div>
      </div>
    </div>
    <div v-else class="no-data">Aucune demande enregistrée</div>

    <custom-modal
      v-if="modal_props.to_confirm"
      @close="modal_props.to_confirm = false"
      @confirm="onConfirmDelete(modal_props.id_to_delete)"
    >
      <template v-slot:modalTitle> {{ modal_props.title }}</template>
      <template v-slot:modalBody> {{ modal_props.body_msg }}</template>
      <template v-slot:closeBtn>Annuler</template>
    </custom-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DemandeDetails from "./DemandeDetails.vue";
import {
  DEMANDE_STATUT,
  DEMANDE_STATUT_CLE,
} from "../../../../../mutuelle-communale-api/app/configs/Properties";
import CustomBasicSelect from "../../../components/customize/CustomBasicSelect.vue";
import CustomModal from "../../../components/customize/CustomModal.vue";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
import CustomSelectAutocomplet from "../../../components/customize/CustomSelectAutocomplet.vue";
import FieldDateInterval from "../../../components/customize/FieldDateInterval.vue";
export default {
  components: {
    DemandeDetails,
    CustomBasicSelect,
    CustomModal,
    CustomInputType,
    CustomSelectAutocomplet,
    FieldDateInterval,
  },
  name: "ListeDemandes",
  props: {
    codes_departements: {
      type: Array,
      default: [],
    },
    actif: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      current_demandes: [],
      nb_pages: 0,
      nb_par_page: 20,
      current_page: 0,
      sorting: {},
      searching: {},
      nbpageOptions: { 10: "10/page", 20: "20/page", 30: "30/page" },
      modal_props: {
        to_confirm: false,
        id_to_delete: null,
        body_msg: "Merci de confirmer votre action",
        title: "Confirmation",
      },
    };
  },
  computed: {
    ...mapGetters(["demandesListe", "departemntsListe"]),
    pageListe() {
      let n = this.current_page * this.nb_par_page;
      let m = n + this.nb_par_page;
      if (this.current_demandes) {
        this.nb_pages = Math.ceil(this.current_demandes.length / this.nb_par_page);
      }
      return this.current_demandes.slice(n, m);
    },
    optionsStatut() {
      let options = [];
      this.$lodash.map(DEMANDE_STATUT_CLE, c => {
        if (c !== DEMANDE_STATUT_CLE.INACTIF) {
          options.push({ value: c, label: DEMANDE_STATUT[c].libelle });
        }
      });
      return options;
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setDepartementDemandesCommunes",
      "deleteDemande",
      "exporterDemandes",
      "setError",
    ]),
    setDemandes() {
      let codes = this.codes_departements?.length > 0 ? this.codes_departements : "all";
      return this.setLoading(true)
        .then(() => this.setDepartementDemandesCommunes({ codes, includecontrats: false }))
        .then(
          () =>
            (this.current_demandes = Array.from(this.demandesListe).filter(c =>
              this.actif
                ? c.statut !== DEMANDE_STATUT_CLE.INACTIF
                : c.statut === DEMANDE_STATUT_CLE.INACTIF
            ))
        )
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onDelete(idCommune) {
      this.modal_props.to_confirm = true;
      this.modal_props.id_to_delete = idCommune;
    },
    onConfirmDelete(id) {
      this.modal_props.to_confirm = false;
      this.modal_props.id_to_delete = null;
      return this.setLoading(true)
        .then(() => this.deleteDemande(id))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    nextPage(i) {
      this.current_page = i - 1 < 0 ? 0 : i - 1 > this.nb_pages - 2 ? this.nb_pages - 1 : i - 1;
    },
    filterListe() {
      this.current_page = 0;
      this.current_demandes = Array.from(
        this.demandesListe.filter(c =>
          this.actif
            ? c.statut !== DEMANDE_STATUT_CLE.INACTIF
            : c.statut === DEMANDE_STATUT_CLE.INACTIF
        )
      );
      this.$lodash.map(this.searching, (search, key) => {
        if (search) {
          search = Array.isArray(search) ? search.join("|") : search;
          let regex = new RegExp(search, "i");
          this.current_demandes = this.current_demandes.filter(demande => {
            let value = key.split(".").reduce((a, b) => a[b], demande);
            switch (key) {
              case "date_creation":
              case "date_rdv":
              case "date_fin_convention":
                let demandeDate = this.$moment(demande[key], "YYYY-MM-DD");
                return search?.startDate && search?.endDate
                  ? demandeDate.isBetween(search.startDate, search.endDate, null, "[]", "day")
                  : search?.startDate
                  ? demandeDate.isSameOrAfter(search.startDate, "day")
                  : search?.endDate
                  ? demandeDate.isSameOrBefore(search.endDate, "day")
                  : true;
              default:
                return regex.test(value);
            }
          });
        }
      });
    },
    resetFilter() {
      this.current_demandes = Array.from(
        this.demandesListe.filter(c =>
          this.actif
            ? c.statut !== DEMANDE_STATUT_CLE.INACTIF
            : c.statut === DEMANDE_STATUT_CLE.INACTIF
        )
      );
      this.searching = {};
    },
    sortListe(key, sortKey) {
      this.current_page = 0;
      this.current_demandes = this.$lodash.orderBy(
        this.current_demandes,
        [key],
        [sortKey ? "asc" : "desc"]
      );
    },
    onExport() {
      return this.setLoading(true)
        .then(() => this.exporterDemandes({ demandes: this.current_demandes }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
  },
  watch: {
    codes_departements: {
      handler(val) {
        return this.setDemandes();
      },
    },
    actif: {
      handler(val) {
        return this.resetFilter();
      },
    },
    demandesListe: {
      handler(val) {
        this.current_demandes = Array.from(
          val.filter(c =>
            this.actif
              ? c.statut !== DEMANDE_STATUT_CLE.INACTIF
              : c.statut === DEMANDE_STATUT_CLE.INACTIF
          )
        );
      },
      deep: true,
    },
    nb_par_page: {
      handler(val) {
        this.current_page = 0;
      },
    },
  },

  mounted() {
    return this.setDemandes();
  },
};
</script>

<style>
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
</style>
