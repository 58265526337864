<template class="demande-liste-communes">
  <h2 v-if="demandes && demandes.length > 0">Communauté de communes de: </h2>
  <table class="table" v-if="demandes && demandes.length > 0">
    <tbody v-if="demandes && demandes.length > 0">
      <template v-for="(demand, index) in demandes" :key="index">
        <tr>
          <td>
            {{ demand.nom_commune }}
            <router-link
              class="btn tooltip-btn"
              :to="{ name: 'Departements.Commune.Workflow', params: { id: demand.id_demande } }"
            >
              <i class="fal fa-search"></i>
              <span class="tooltip-text">Visualiser</span>
            </router-link>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DemandeListeCommunes",
  props: {
    demande: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      demandes: [],
    };
  },
  methods: {
    ...mapActions(["getDemandesByCommunaute", "setLoading"]),
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.getDemandesByCommunaute(this.demande.nom_commune))
      .then(demandes => (this.demandes = demandes))
      .catch(e => this.setError(e))
      .then(() => this.setLoading(false));
  },
};
</script>
