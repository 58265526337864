<template>
  <div :class="[, validated && errors ? 'field-error' : '']">
    <div class="field field-date">
      <label class="form-label" v-if="label"> {{ label }}</label>

      <div class="input-date start">
        <input
          class="field date"
          :type="type"
          :value="modelValue?.[`${name}`]"
          :placeholder="placeholder"
          @change="e => onChange(`${name}`, e.target.value)"
          @input="e => onChange(`${name}`, e.target.value)"
          @blur="validated = true"
          autocomplete="off"
          :min="min"
        />
      </div>
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { min } from "lodash";
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
    min: {
      type: String,
    },
    name: {
      type: String,
      default: "date",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object,
      default: { date: null },
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      validated: false,
      current_options: [],
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(key, value) {
      this.$emit("change");
      this.$emit("update:modelValue", { ...this.modelValue, [key]: value });
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped>
.field-date {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0;
}
.field-date .date {
  margin: 0;
  margin-right: 10px;
}
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}
</style>
