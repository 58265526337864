/*****************************************************************************
 * Properties
 *****************************************************************************/

export const ACCOUNTS_ROLES = Object.freeze({
  '@ADMIN': '@ADMIN',
  '@DA': '@DA'
});

export const WORKFLOW_STEPS = Object.freeze({
  NOUVEAU: 'NOUVEAU',
  DOSSIER: 'DOSSIER',
  PRESENTATION: 'PRESENTATION',
  CONVENTION: 'CONVENTION',
  INACTIF: 'INACTIF'
});

export const DEMANDE_STATUT_CLE = Object.freeze({
  NOUVEAU: 'NOUVEAU',
  CREATION: 'CREATION',
  PRESENTATION: 'PRESENTATION',
  SIGNATURE: 'SIGNATURE',
  A_VALIDER: 'A_VALIDER',
  ACTIF: 'ACTIF',
  A_RENOUVELER : 'A_RENOUVELER',
  INACTIF: 'INACTIF'
});

export const DEMANDE_STATUT = Object.freeze({
  [DEMANDE_STATUT_CLE.NOUVEAU]: {
    libelle: 'Nouveau',
    step_workflow: WORKFLOW_STEPS.NOUVEAU
  },
  [DEMANDE_STATUT_CLE.CREATION]: {
    libelle: 'En cours de création',
    step_workflow: WORKFLOW_STEPS.DOSSIER
  },
  [DEMANDE_STATUT_CLE.PRESENTATION]: {
    libelle: 'En cours de présentation',
    step_workflow: WORKFLOW_STEPS.PRESENTATION
  },
  [DEMANDE_STATUT_CLE.SIGNATURE]: {
    libelle: 'En cours de signature',
    step_workflow: WORKFLOW_STEPS.CONVENTION
  },
  [DEMANDE_STATUT_CLE.A_VALIDER]: {
    libelle: 'A valider',
    step_workflow: WORKFLOW_STEPS.CONVENTION
  },
  [DEMANDE_STATUT_CLE.ACTIF]: {
    libelle: 'Actif',
    step_workflow: WORKFLOW_STEPS.CONVENTION
  },
  [DEMANDE_STATUT_CLE.A_RENOUVELER]: {
    libelle: 'À renouveler',
    step_workflow: WORKFLOW_STEPS.CONVENTION
  },
  [DEMANDE_STATUT_CLE.INACTIF]: {
    libelle: 'Inactif',
    step_workflow: WORKFLOW_STEPS.INACTIF
  }
});

export const EXCEL_COMMUNES_HEADERS = Object.freeze({
  date_rdv: 'Date de RDV avec la commune',
  date_creation: 'Date de création',
  nom_commune: 'Nom de la commune concernée',
  snow_reference: 'Élément de demande',
  statut: 'Statut',
  rds_nom: 'Bénéficiaire',
  rds_email: 'Adresse mail du bénéficiaire',
  code_departement: 'Agence de rattachement de  la commune',
  agence: 'Information agence de rattachement de la commune',
  charge_affaires_nom: "Nom du chargé d'affaires collectivités concerné",
  charge_affaires_email: "Email GED du Chargé d'Affaires Collectivités",
  commentaire: 'Commentaires  - précisions',
  candidature: 'Envoyé par mail',
  secteur: 'Secteur de rattachement '
});

export const EXCEL_CONTRATS_HEADERS = Object.freeze({
  idgrc: 'Id GRC personne',
  csp: 'Code CSP',
  age: 'Age',
  date_affectation: "Date d'affectation de l'avantage",
  formule: 'Code régime assurance sociale personne assurée 1',
  ville: 'Ville',
  code_departement: 'Code INSEE Département',
  cotisation_annuelle: 'Montant cotisation annuelle nette HT',
  motif_contrat: 'Motif contrat',
});
export const EXCEL_CONTRATS_HEADERS_ROW = 11;
export const EXCEL_STATUTS_CLE = Object.freeze({
  Clos: 'Clos',
  'En cours de traitement': 'En cours de traitement',
  Ouvert: 'Ouvert'
});
export const EXCEL_CANDIDATURE = Object.freeze({
  faux: 'Présentée à la commune/CCAS',
  vrai: 'Envoyée par mail'
});
export const EXCEL_CODE_CSP = Object.freeze({
  0: 'Inconnu',
  1: 'Exploitant',
  3: 'Artisan',
  4: 'Commerçant',
  5: "Chef d'entreprise",
  6: ' Profession libérale',
  7: 'Salarié',
  8: ' Retraité',
  9: 'Inactif'
});

export const STATUT_MAJ = Object.freeze({
  [DEMANDE_STATUT_CLE.NOUVEAU]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.NOUVEAU,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.CREATION,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.PRESENTATION
  },
  [DEMANDE_STATUT_CLE.CREATION]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.CREATION,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.CREATION,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.PRESENTATION
  },
  [DEMANDE_STATUT_CLE.PRESENTATION]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.PRESENTATION,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.CREATION,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.PRESENTATION
  },
  [DEMANDE_STATUT_CLE.SIGNATURE]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.SIGNATURE,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.SIGNATURE,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.SIGNATURE
  },
  [DEMANDE_STATUT_CLE.A_VALIDER]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.A_VALIDER,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.A_VALIDER,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.A_VALIDER
  },
  [DEMANDE_STATUT_CLE.ACTIF]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.ACTIF,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.ACTIF,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.ACTIF
  },
  [DEMANDE_STATUT_CLE.A_RENOUVELER]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.A_RENOUVELER,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.A_RENOUVELER,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.A_RENOUVELER
  },
  [undefined]: {
    [EXCEL_STATUTS_CLE.Ouvert]: DEMANDE_STATUT_CLE.NOUVEAU,
    [EXCEL_STATUTS_CLE['En cours de traitement']]: DEMANDE_STATUT_CLE.CREATION,
    [EXCEL_STATUTS_CLE.Clos]: DEMANDE_STATUT_CLE.PRESENTATION
  }
});
