<template>
  <div class="filters">
    <div class="flexbox alignCenter">
      <custom-multi-check-box
        class="choix-departements"
        :buttons="departementsBtns"
        v-model="stats.codes_departements"
      />
      <div class="flex-1 justifyCenter fields-block">
        <field-date-interval v-model="stats.date" />
      </div>
      <div class="d-flex align-items-center">
        <button class="btn btn-secondary btn-small" @click="resetFilter">
          <i class="fa-regular fa-refresh" />
        </button>
      </div>
    </div>
    <div class="separator" />
    <div class="row mb-10">
      <div class="col-4">
        <custom-select-autocomplet
          :options="typesOptions"
          v-model="stats.type_ca"
          placeholder="Type"
          label="CA"
        />
      </div>
      <div class="col-8">
        <div class="flexbox alignEnd fullWidth fullHeight">
          <field-comparator v-model="stats.comparator" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <custom-select-autocomplet
          :multiple="true"
          label="Commune"
          :options="communesOptions"
          v-model="stats.nom_commune"
          placeholder="Commune"
        />
      </div>
      <div class="col">
        <custom-select-autocomplet
          :multiple="true"
          label="RDS"
          :options="rdsOptions"
          v-model="stats.rds"
          placeholder="RDS"
        />
      </div>
      <div class="col">
        <custom-select-autocomplet
          :multiple="true"
          label="Secteur"
          :options="secteurOptions"
          v-model="stats.secteur"
          placeholder="Secteur"
        />
      </div>

      <div class="fields-block">
        <button v-if="isUserLogged" @click="to_update = true" class="btn btn-primary">
          <i class="fa-solid fa-arrow-up-from-bracket"></i>
          <span>MAJ contrats</span>
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="stats">
      <div class="row justify-content-center mt-60 mb-40">
        <div class="col-12 col-md-4 col-lg">
          <div class="card-stats" v-if="stats.nom_commune.length === 0">
            <span class="card-stats-data">{{ stats.data.nbr_communes }}</span>
            <span class="card-stats-label">{{
              getPlurielWord("commune", stats.data.nbr_communes)
            }}</span>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg">
          <div class="card-stats">
            <span class="card-stats-data">{{ stats.data.nbr_conventions }}</span>
            <span class="card-stats-label">
              {{ getPlurielWord("convention", stats.data.nbr_conventions) }}
              {{ getPlurielWord("signée", stats.data.nbr_conventions) }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg">
          <div class="card-stats">
            <span class="card-stats-data">{{ stats.data.nbr_conventions_renouvelees }}</span>
            <span class="card-stats-label">
              {{ getPlurielWord("convention", stats.data.nbr_conventions_renouvelees) }}
              {{ getPlurielWord("renouvelée", stats.data.nbr_conventions_renouvelees) }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg">
          <div class="card-stats">
            <span class="card-stats-data">{{ stats.data.nbr_contrats }}</span>
            <span class="card-stats-label">
              {{ getPlurielWord("contrat", stats.data.nbr_contrats) }}
              {{ getPlurielWord("signé", stats.data.nbr_contrats) }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg">
          <div class="card-stats">
            <span class="card-stats-data">{{
              (~~stats.data.cotisation_annuelle).toLocaleString()
            }}</span>
            <span class="card-stats-label">€ HT de cotisation annuelle </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="stats-age">
                <data-chart
                  chartType="Doughnut"
                  :options="stats.options.doughnut"
                  :data="stats.data.age"
                  :height="250"
                  :width="250"
                  title="Répartition par âge"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="stats-csp">
                <data-chart
                  chartType="Doughnut"
                  :options="stats.options.doughnut"
                  :data="stats.data.csp"
                  :height="250"
                  :width="250"
                  title="Répartition par CSP"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="stats-formule">
                <data-chart
                  chartType="Doughnut"
                  :options="stats.options.doughnut"
                  :data="stats.data.formule"
                  :height="250"
                  :width="250"
                  title="Formule"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="stats-motif">
                <data-chart
                  chartType="Doughnut"
                  :options="stats.options.doughnut"
                  :data="stats.data.motif"
                  :height="250"
                  :width="250"
                  title="Affaire nouvelle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="stats.nom_commune.length === 0">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-9">
              <div class="stats-bar">
                <data-chart
                  chartType="Bar"
                  :data="stats.data.communes"
                  :options="stats.options.default"
                  :height="300"
                  :width="700"
                  title="Année en cours"
                  customTitleClass="test"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3" v-if="hasRefus">
              <div class="stats-refus">
                <data-chart
                  chartType="Doughnut"
                  :options="stats.options.doughnut"
                  :data="stats.data.refus"
                  :height="250"
                  :width="250"
                  title="Refus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <update-data v-if="to_update" data_type="contrats" @updateDone="setStatsData" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DEMANDE_STATUT_CLE } from "../../../../mutuelle-communale-api/app/configs/Properties";
import CustomBasicSelect from "../../components/customize/CustomBasicSelect.vue";
import CustomMultiCheckBox from "../../components/customize/CustomMultiCheckBox.vue";
import CustomSelectAutocomplet from "../../components/customize/CustomSelectAutocomplet.vue";
import DataChart from "../../components/customize/DataChart.vue";
import FieldDateInterval from "../../components/customize/FieldDateInterval.vue";
import FieldComparator from "../../components/customize/FieldComparator.vue";
import UpdateData from "./UpdateData.vue";
import { normalizePlurielWord } from "../../utils/globalsMethods";
export default {
  data() {
    return {
      to_update: false,
      rdsOptions: [],
      secteurOptions: [],
      stats: {
        codes_departements: [],
        date: { startDate: null, endDate: null },
        nom_commune: [],
        comparator: { type: null, input1: null, input2: null },
        type_ca: null,
        rds: [],
        secteur: [],
        data: {
          communes: {
            labels: this.$moment.monthsShort(),
            datasets: [
              {
                label: "Nouveau",
                index: "data-0",
                backgroundColor: "#A4A1FB",
                data: [],
                borderRadius: 15,
              },
              {
                label: "Signé",
                index: "data-1",
                backgroundColor: "#56D9FE",
                data: [],
                borderRadius: 15,
              },
              {
                label: "Abandon",
                index: "data-2",
                backgroundColor: "#5FE3A1",
                data: [],
                borderRadius: 15,
              },
            ],
          },
          age: {
            labels: [
              "<= 18 ans",
              "19 -25 ans",
              "25 -30 ans",
              "30 -35 ans",
              "35 -40 ans",
              "40 -45 ans",
              "45 -50 ans",
              "50 -55 ans",
              "55 -60 ans",
              "60 -65 ans",
              "65 -70 ans",
              "70 -75 ans",
              "75 -80 ans",
              "80 - 85 ans",
              ">= 85 ans",
            ],
            datasets: [
              {
                backgroundColor: [
                  "#A3A0FA",
                  "#55D8FC",
                  "#FF8373",
                  "#FFDA83",
                  "#E973FF",
                  "#73FFB9",
                  "#FFAE73",
                  "#7378FF",
                  "#A9FF73",
                  "#FF73AE",
                  "#83FFFF",
                  "#FAFF73",
                  "#73A9FF",
                  "#73FF78",
                  "#FF9973",
                  "#C4FF73",
                ],
                data: [],
              },
            ],
          },
          csp: {
            labels: [
              "Inconnu",
              "Exploitant",
              "Artisan",
              "Commerçant",
              "Chef d'entreprise",
              "Profession libérale",
              "Salarié",
              "Retraité",
              "Inactif",
            ],
            datasets: [
              {
                backgroundColor: [
                  "#A3A0FA",
                  "#55D8FC",
                  "#FF8373",
                  "#FFDA83",
                  "#E973FF",
                  "#73FFB9",
                  "#FFAE73",
                  "#7378FF",
                  "#A9FF73",
                  "#FF73AE",
                  "#83FFFF",
                  "#FAFF73",
                ],
                data: [],
              },
            ],
          },
          refus: {
            labels: ["Autre concurrent", "Abandon du projet", "Autre"],
            datasets: [
              {
                backgroundColor: ["#A3A0FA", "#55D8FC", "#FF8373", "#FFDA83"],
                data: [],
              },
            ],
          },
          formule: {
            labels: ["Classique", "Commune"],
            datasets: [
              {
                backgroundColor: ["#A3A0FA", "#EDECFE"],
                data: [],
              },
            ],
          },
          motif: {
            labels: ["Aff. Nouvelle", "Remplacement"],
            datasets: [
              {
                backgroundColor: ["#A3A0FA", "#EDECFE"],
                data: [],
              },
            ],
          },
          nbr_communes: 0,
          nbr_contrats: 0,
          nbr_conventions: 0,
          nbr_conventions_renouvelees: 0,
          cotisation_annuelle: 0,
        },
        options: {
          doughnut: {
            responsive: true,
            cutout: "70%",
            plugins: {
              legend: {
                position: "right",
                labels: {
                  filter: (legendItem, data) => data.datasets[0].data[legendItem.index] !== 0,
                  usePointStyle: true,
                  boxWidth: 8,
                  boxHeight: 8,
                  textAlign: "left",
                  generateLabels: chart => {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((data, i) => ({
                      text: `${chart.data.labels[i]} (${data})`,
                      strokeStyle: datasets[0].backgroundColor[i],
                      lineWidth: 4,
                      fillStyle: "transparent",
                      hidden: !chart.getDataVisibility(i),
                      index: i,
                    }));
                  },
                },
              },
            },
          },
          default: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
                labels: {
                  filter: (legendItem, data) =>
                    data.datasets[legendItem.datasetIndex].data.reduce((a, b) => {
                      a += b;
                      return a;
                    }, 0) > 0,
                  usePointStyle: true,
                  textAlign: "left",
                  boxWidth: 10,
                  boxHeight: 10,
                  generateLabels: chart => {
                    const datasets = chart.data.datasets;
                    return datasets.map((data, i) => ({
                      text: `${datasets[i].label} (${data.data.reduce((a, b) => {
                        a += b;
                        return a;
                      }, 0)})`,
                      strokeStyle: datasets[i].backgroundColor,
                      lineWidth: 5,
                      fillStyle: "white",
                      hidden: !chart.isDatasetVisible(i),
                      datasetIndex: i,
                    }));
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  components: {
    CustomMultiCheckBox,
    FieldDateInterval,
    FieldComparator,
    CustomBasicSelect,
    DataChart,
    CustomSelectAutocomplet,
    UpdateData,
  },
  name: "DemandesStats",
  computed: {
    ...mapGetters(["demandesListe", "departemntsListe", "isUserLogged"]),
    departementsBtns() {
      let btns = [];
      this.departemntsListe.map(d =>
        btns.push({ label: d.code_departement, value: d.code_departement })
      );
      return btns;
    },
    typesOptions() {
      let demandes = [
        { value: "contrats", label: "Contrat" },
        { value: "communal", label: "Communal" },
      ];
      return demandes || [];
    },
    statsFilter() {
      return {
        codes_departements: this.stats.codes_departements,
        date: this.stats.date,
        type_ca: this.stats.type_ca,
        comparator: this.stats.comparator,
        rds: this.stats.rds,
        secteur: this.stats.secteur,
        nom_commune: this.stats.nom_commune,
      };
    },
    communesOptions() {
      let demandes =
        this.demandesListe &&
        this.demandesListe.filter(demande => {
          if (!(this.stats.rds + this.stats.secteur + this.stats.codes_departements)) return true;
          if (
            demande?.date_creation &&
            new Date(demande?.date_creation).getFullYear() === new Date().getFullYear()
          ) {
            if (this.stats.secteur.length > 0 && this.stats.secteur.includes(demande.secteur)) {
              return true;
            }
            if (this.stats.rds.length > 0 && this.stats.rds.includes(demande.rds_nom)) {
              return true;
            }
            if (
              this.stats.codes_departements.length > 0 &&
              this.stats.codes_departements.includes(demande.code_departement)
            ) {
              return true;
            }
          }
          return false;
        });
      return this.getOptions("nom_commune", demandes) || [];
    },
    hasRefus() {
      return (
        this.stats?.data?.refus?.datasets[0]?.data?.reduce((a, b) => {
          a += b;
          return a;
        }, 0) > 0
      );
    },
  },
  methods: {
    ...mapActions([
      "setDepartementsListe",
      "setLoading",
      "setError",
      "setDepartementDemandesCommunes",
    ]),
    setStatsData() {
      const existDataComparator =
        (this.stats.comparator.type === "Compris entre" &&
          this.stats.comparator.input1 &&
          this.stats.comparator.input2) ||
        (this.stats.comparator.type &&
          (this.stats.comparator.type === "Inférieur ou égal à" ||
            this.stats.comparator.type === "Supérieur à") &&
          this.stats.comparator.input1);
      // Filtre la liste en se basant sur rds, secteur et code departement
      let filteredDemandes =
        this.demandesListe &&
        this.demandesListe.filter(d => {
          let demande = d;
          if (this.stats.secteur.length > 0 && !this.stats.secteur.includes(demande.secteur)) {
            return false;
          }
          if (
            this.stats.nom_commune.length > 0 &&
            !this.stats.nom_commune.includes(demande.nom_commune)
          ) {
            return false;
          }
          if (this.stats.rds.length > 0 && !this.stats.rds.includes(demande.rds_nom)) {
            return false;
          }
          if (
            this.stats.codes_departements.length > 0 &&
            !this.stats.codes_departements.includes(demande.code_departement)
          ) {
            return false;
          }
          if (demande?.communaute) {
            demande = demande?.communaute;
          }
          // Ajouter comme condition que si pour contrats et qu'il n'existe pas de contrats dans la commune correspondant aux filtres , on rejete la commune
          if (this.stats.type_ca && this.stats.type_ca === "contrats" && existDataComparator) {
            if (
              this.stats.comparator.type === "Inférieur ou égal à" &&
              demande.contrats &&
              demande.contrats?.filter(
                contrat => contrat.cotisation_annuelle <= this.stats.comparator.input1
              ).length <= 0
            )
              return false;
            if (
              this.stats.comparator.type === "Supérieur à" &&
              demande.contrats &&
              demande.contrats?.filter(
                contrat => contrat.cotisation_annuelle > this.stats.comparator.input1
              ).length <= 0
            )
              return false;
            if (
              this.stats.comparator.type === "Compris entre" &&
              demande.contrats &&
              demande.contrats?.filter(
                contrat =>
                  contrat.cotisation_annuelle >= this.stats.comparator.input1 &&
                  contrat.cotisation_annuelle < this.stats.comparator.input2
              ).length <= 0
            )
              return false;
          }
          if (this.stats.type_ca && this.stats.type_ca === "communal" && existDataComparator) {
            let totalCommune = 0;
            demande.contrats &&
              demande.contrats.map(contrat => (totalCommune += contrat.cotisation_annuelle));
            if (
              this.stats.comparator.type === "Inférieur ou égal à" &&
              totalCommune > this.stats.comparator.input1
            )
              return false;
            if (
              this.stats.comparator.type === "Supérieur à" &&
              totalCommune <= this.stats.comparator.input1
            )
              return false;
            if (
              this.stats.comparator.type === "Compris entre" &&
              (totalCommune < this.stats.comparator.input1 ||
                totalCommune >= this.stats.comparator.input2)
            )
              return false;
          }
          return true;
        });
      this.rdsOptions = this.getOptions("rds_nom", this.demandesListe);
      this.secteurOptions = this.getOptions("secteur", this.demandesListe);
      let refusLabels = this.filpKeyValue(this.stats.data.refus.labels);
      let cspLabels = this.filpKeyValue(this.stats.data.csp.labels);
      let ageLabels = this.filpKeyValue(this.stats.data.age.labels);
      let formuleLabels = this.filpKeyValue(this.stats.data.formule.labels);
      let motifLabels = this.filpKeyValue(this.stats.data.motif.labels);
      this.stats.data.nbr_communes = filteredDemandes.filter(d =>
        d?.communaute
          ? d.communaute.statut !== DEMANDE_STATUT_CLE.INACTIF
          : d.statut !== DEMANDE_STATUT_CLE.INACTIF
      ).length;
      this.stats.data.nbr_contrats = 0;
      this.stats.data.cotisation_annuelle = 0;
      this.stats.data.nbr_conventions = 0;
      this.stats.data.nbr_conventions_renouvelees = 0;
      this.stats.data.nbr_conventions_non_renouvelees = 0;

      this.stats.data.communes.datasets[0].data = this.zerosArray(12);
      this.stats.data.communes.datasets[1].data = this.zerosArray(12);
      this.stats.data.communes.datasets[2].data = this.zerosArray(12);
      this.stats.data.refus.datasets[0].data = this.zerosArray(Object.keys(refusLabels).length);
      this.stats.data.motif.datasets[0].data = this.zerosArray(Object.keys(motifLabels).length);
      this.stats.data.formule.datasets[0].data = this.zerosArray(Object.keys(formuleLabels).length);
      this.stats.data.age.datasets[0].data = this.zerosArray(Object.keys(ageLabels).length);
      this.stats.data.csp.datasets[0].data = this.zerosArray(Object.keys(cspLabels).length);
      let communautesTraitees = [];
      filteredDemandes.forEach(d => {
        let demande = d;
        if (
          communautesTraitees &&
          (communautesTraitees.includes(demande.nom_commune) ||
            communautesTraitees.includes(demande?.nom_communaute))
        ) {
          return null;
        } else {
          if (demande.is_communaute) {
            communautesTraitees.push(demande.nom_commune);
          }
          if (demande.communaute) {
            communautesTraitees.push(demande?.nom_communaute);
            demande = this.demandesListe.find(d => d.nom_commune === demande?.nom_communaute);
          }

          let demandeDate = new Date(demande?.date_creation);

          switch (demande?.statut) {
            case DEMANDE_STATUT_CLE.NOUVEAU:
            case DEMANDE_STATUT_CLE.CREATION:
            case DEMANDE_STATUT_CLE.PRESENTATION:
            case DEMANDE_STATUT_CLE.SIGNATURE:
              this.isInCurrentYear(demandeDate) &&
                this.stats.data.communes.datasets[0].data[demandeDate.getMonth()]++;
              if (demande.is_renouveler) {
                this.stats.data.nbr_conventions_renouvelees++;
              }
              break;
            case DEMANDE_STATUT_CLE.A_VALIDER:
              demandeDate = new Date(demande.date_depot_convention);
              // demandeMonth = .getMonth();
              this.isInCurrentYear(demandeDate) &&
                this.stats.data.communes.datasets[1].data[demandeDate.getMonth()]++;
              if (demande.is_renouveler) {
                this.stats.data.nbr_conventions_renouvelees++;
              }
              break;
            case DEMANDE_STATUT_CLE.ACTIF:
            case DEMANDE_STATUT_CLE.A_RENOUVELER:
              this.stats.data.nbr_conventions++;
              if (demande.is_renouveler) {
                this.stats.data.nbr_conventions_renouvelees++;
              }
              demandeDate = new Date(demande?.date_convention);
              this.isInCurrentYear(demandeDate) &&
                this.stats.data.communes.datasets[1].data[demandeDate.getMonth()]++;
              // Set Contrats stats
              demande.contrats &&
                demande.contrats
                  .filter(c => {
                    let dateAffectation = this.$moment(c.date_affectation);
                    return this.stats.date.startDate && this.stats.date.endDate
                      ? dateAffectation.isBetween(
                          this.stats.date.startDate,
                          this.stats.date.endDate,
                          null,
                          "[]",
                          "day"
                        )
                      : this.stats.date.startDate
                      ? dateAffectation.isSameOrAfter(this.stats.date.startDate, "day")
                      : this.stats.date.endDate
                      ? dateAffectation.isSameOrBefore(this.stats.date.endDate, "day")
                      : true;
                  })
                  .forEach(contrat => {
                    // Ajouter comme condition que si pour contrats et qu'il n'existe pas de contrats dans la commune correspondant aux filtres , on rejete la commune
                    if (
                      this.stats.type_ca &&
                      this.stats.type_ca === "contrats" &&
                      existDataComparator
                    ) {
                      if (
                        (this.stats.comparator.type === "Inférieur ou égal à" &&
                          contrat.cotisation_annuelle <= this.stats.comparator.input1) ||
                        (this.stats.comparator.type === "Supérieur à" &&
                          contrat.cotisation_annuelle > this.stats.comparator.input1) ||
                        (this.stats.comparator.type === "Compris entre" &&
                          contrat.cotisation_annuelle >= this.stats.comparator.input1 &&
                          contrat.cotisation_annuelle < this.stats.comparator.input2)
                      ) {
                        // Rien à faire mais on continue à prendre les données du contrats
                      } else return;
                    }
                    // nbr_contrats
                    this.stats.data.nbr_contrats += 1;
                    // cotisation annuelle
                    this.stats.data.cotisation_annuelle += contrat?.cotisation_annuelle || 0;

                    // formule
                    this.stats.data.formule.datasets[0].data[
                      formuleLabels[contrat.formule.trim()]
                    ]++;
                    // Motif
                    const motifIndex = /remplacement$/i.test(contrat.motif_contrat) ? 1 : 0;
                    this.stats.data.motif.datasets[0].data[motifIndex]++;
                    // csp
                    this.stats.data.csp.datasets[0].data[cspLabels[contrat.csp.trim()]]++;
                    // age
                    let ageInterval = [-1, 19, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85];
                    let ageDataIndex = ageInterval.findIndex(age => age > contrat.age);
                    ageDataIndex = ageDataIndex < 0 ? Object.keys(ageLabels).length : ageDataIndex;
                    this.stats.data.age.datasets[0].data[ageDataIndex - 1]++;
                  });

              break;
            case DEMANDE_STATUT_CLE.INACTIF:
              demandeDate = new Date(demande.date_abandon);
              // communes abandon
              if (demande.is_renouveler === "0") {
                this.stats.data.nbr_conventions_non_renouvelees++;
              }
              if (this.isInCurrentYear(demandeDate)) {
                this.stats.data.communes.datasets[2].data[demandeDate.getMonth()]++;
                // refus
                let refusDataindex = /^Autre/.test(demande.motif_abandon)
                  ? refusLabels["Autre"]
                  : /concurrent$/.test(demande.motif_abandon)
                  ? refusLabels["Autre concurrent"]
                  : refusLabels[demande.motif_abandon];
                this.stats.data.refus.datasets[0].data[refusDataindex]++;
              }
              break;
          }
        }
      });
    },
    resetFilter() {
      this.stats.codes_departements = [];
      this.stats.date = { startDate: null, endDate: null };
      this.stats.nom_commune = [];
      this.stats.comparator = { type: null, input1: null, input2: null };
      this.stats.comparator.input1 = null;
      this.stats.type_ca = null;
      this.stats.rds = [];
      this.stats.secteur = [];
    },
    filpKeyValue(arr) {
      return Object.fromEntries(Object.entries(arr).map(([key, value]) => [value, +key]));
    },
    zerosArray(n) {
      return Array.from({ length: n }, () => 0);
    },
    getOptions(prop, liste) {
      let options = [];
      Array.from(new Set(liste.map(d => d[prop]))).map(op => {
        options.push({ value: op, label: op });
      });
      return options;
    },
    getPlurielWord(word, n) {
      return normalizePlurielWord(word, n);
    },
    isInCurrentYear(date) {
      return date?.getFullYear() === new Date().getFullYear();
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.setDepartementsListe())
      .then(() => this.setDepartementDemandesCommunes({ codes: "all", includecontrats: true }))
      .then(() => this.setStatsData())
      .catch(e => this.setError(e))
      .then(() => this.setLoading(false));
  },
  watch: {
    statsFilter: {
      deep: true,
      handler(val) {
        this.setStatsData();
      },
    },
  },
};
</script>
