<template>
  <div class="container-fluid">
    <div class="page-header">
      <div class="container-fluid">
        <div class="row justify-content-between">
          <div class="col-2">
            <router-link :to="{ name: 'Departements' }">
              <span><i class="fa-solid fa-arrow-left-long"></i> Retour </span></router-link
            >
          </div>
          <div class="col-auto">
            <h1 v-if="demande && demande.nom_commune">{{ demande.nom_commune }}</h1>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
    <div v-if="demande" class="page-content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-4 col-xs-12">
            <!-- Steps Line -->
            <div class="step-line">
              <h2>Suivi</h2>
              <div class="steps">
                <template v-for="step in steps_workflow">
                  <div
                    class="step-wrapper"
                    v-if="!(step.ordre === 5 && is_actif)"
                    :key="step.ordre"
                    :class="{
                      validated: !!has_communaute()?.[step.date_prop],
                      active: demande_step.ordre === step.ordre,
                    }"
                  >
                    <div class="step-block">
                      <span class="step-icon"> <i :class="step.icon"></i></span>
                      <span class="step-content">
                        <span>{{ step.libelle }} </span>
                        <span
                          v-if="
                            has_communaute()?.[step.date_prop] &&
                            (demande_step.ordre > step.ordre ||
                              (step.ordre === 1 && is_statut(statuts.NOUVEAU)) ||
                              (step.ordre === 5 && is_statut(statuts.INACTIF)))
                          "
                        >
                          Fait le
                          {{ $moment(has_communaute()?.[step.date_prop]).format("DD/MM/YYYY") }}
                        </span>
                        <span
                          v-else-if="demande_step.ordre === 4 && has_communaute()?.[step.date_prop]"
                          >Signé le
                          {{ $moment(has_communaute()?.[step.date_prop]).format("DD/MM/YYYY") }}
                        </span>

                        <span v-else-if="demande_step.ordre === step.ordre">En cours</span>
                        <span v-if="step.ordre === 4 && has_communaute()?.date_fin_convention"
                          >valable jusqu'au
                          {{
                            $moment(has_communaute()?.date_fin_convention).format("DD/MM/YYYY")
                          }}</span
                        >
                      </span>
                    </div>
                    <span className="step-line"></span>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="is_statut(statuts.ACTIF) || is_statut(statuts.SIGNATURE)">
              <a
                :href="support_presentation_path"
                download
                class="btn btn-primary mb-1"
                target="_blank"
              >
                <span>Générer le support de présentation aux administrés</span>
              </a>
            </div>
            <div>
              <!-- Générer la convention -->
              <div v-if="is_statut(statuts.PRESENTATION) || is_statut(statuts.SIGNATURE)">
                <button class="btn btn-primary" @click="onGetConvention">
                  <span>Générer la convention</span><i class="fa-solid fa-arrow-right-long"></i>
                </button>
                <a
                  ref="resultatConvention"
                  hidden
                  :href="urlOutput"
                  :download="outputFile"
                  target="_blank"
                  >Télécharger le document</a
                >
              </div>

              <!-- Charger et modifier la convention signée -->
              <div
                class="flex-btn-group mb-14"
                v-if="is_statut(statuts.SIGNATURE) || is_statut(statuts.A_VALIDER)"
              >
                <a
                  v-if="!edit_convention && convention_path && is_statut(statuts.A_VALIDER)"
                  :href="convention_path"
                  download
                  class="btn btn-link"
                  target="_blank"
                >
                  <i class="fa-solid fa-download"></i> <span>Télécharger la convention</span>
                </a>
                <div
                  class="box box-highlight d-flex flex-column"
                  v-if="is_statut(statuts.SIGNATURE) || edit_convention || !convention_path"
                >
                  <label
                    >Charger la convention signée {{ !demande.is_renouveler ? "*" : "" }}</label
                  >
                  <field-dropzone-file
                    v-model="convention_file"
                    :rules="!demande.is_renouveler && validations.convention"
                    ref="convention_file"
                    :acceptFilesType="[
                      'application/pdf',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    ]"
                  />
                  <field-date
                    label="Date fin convention"
                    v-model="date_fin_convention"
                    :name="'date_fin_convention'"
                    :min="$moment().format('YYYY-MM-DD')"
                    :rules="validations.date_fin_convention"
                  />
                  <div class="mt-14 d-flex justify-content-center">
                    <button
                      class="btn btn-secondary btn-medium"
                      @click="edit_convention = false"
                      v-if="edit_convention"
                    >
                      Annuler
                    </button>
                    <button class="btn btn-primary btn-medium" @click="onUpdateConvention">
                      <span>Valider</span><i class="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </div>
                </div>
                <button
                  v-if="!is_statut(statuts.SIGNATURE) && !edit_convention && convention_path"
                  @click="edit_convention = true"
                  class="btn btn-medium btn-primary"
                >
                  Modifier
                </button>
              </div>

              <!-- Voir la convention -->
              <div v-if="is_statut(statuts.ACTIF)">
                <div class="btn-group">
                  <a
                    class="btn btn-primary"
                    :href="has_communaute()?.convention_lien"
                    target="_blank"
                  >
                    <span>Voir la convention</span><i class="fa-solid fa-arrow-right-long"></i>
                  </a>
                </div>
                <div class="btn-group ml-5">
                  <button
                    v-if="!edit_convention_lien && isUserLogged"
                    @click="onEditConventionLien"
                    class="btn btn-medium btn-primary"
                  >
                    Modifier le lien
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 col-xs-12">
            <!-- informations de la demande -->
            <div class="infos">
              <h2>Informations</h2>
              <a
                class="lien-dossier"
                href="https://groupama.service-now.com/services_portal_groupama?id=gsp_mes_commande"
                target="_blank"
                >{{ has_communaute()?.snow_reference }}</a
              >
              <demande-infos :demande="has_communaute()" :communaute="communaute" />
            </div>
          </div>
          <div
            class="col-4 col-xs-12"
            v-if="
              demande.is_communaute ||
              (has_communaute()?.conventions &&
                JSON.parse(has_communaute()?.conventions).length > 0)
            "
          >
            <!-- informations de la demande -->
            <div class="infos">
              <demande-liste-communes v-if="demande.is_communaute" :demande="demande" />
              <h2
                v-if="
                  has_communaute()?.conventions &&
                  JSON.parse(has_communaute()?.conventions).length > 0
                "
              >
                Historique conventions
              </h2>
              <table
                class="table"
                v-if="
                  has_communaute()?.conventions &&
                  JSON.parse(has_communaute()?.conventions).length > 0
                "
              >
                <thead>
                  <tr>
                    <th>
                      <div class="th-title">
                        <span>Date convention</span>
                      </div>
                    </th>
                    <th>
                      <div class="th-title">
                        <span>Convention</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody
                  v-if="
                    has_communaute()?.conventions &&
                    JSON.parse((has_communaute()?.conventions)).length > 0
                  "
                >
                  <template
                    v-for="(conv, index) in this.$lodash.sortBy(
                      JSON.parse(has_communaute()?.conventions, date_convention, 'desc'),
                    )"
                    :key="index"
                  >
                    <tr>
                      <td>
                        <span>{{
                          conv.date_convention
                            ? this.$moment(conv.date_convention).format("DD/MM/YYYY")
                            : "-"
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="conv.convention_lien"
                          :href="conv.convention_lien"
                          target="_blank"
                          >Consulter <i class="fa-solid fa-file"></i
                        ></a>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <div v-else>Aucun résultat</div>
              </table>
            </div>
          </div>
          <div class="col-8">
            <!-- Valider le lien de la convention -->
            <div
              class="d-flex mt-3"
              v-if="(is_statut(statuts.A_VALIDER) || edit_convention_lien) && isUserLogged"
            >
              <custom-input-type
                type="text"
                v-model="convention_lien"
                class="inline full-height flex-grow-1 mb-0"
                :rules="validations.convention_lien"
                ref="convention_lien"
              />
              <button
                class="btn btn-primary ml-14"
                @click="edit_convention_lien ? submitNewConventionLien() : onValiderConvention()"
              >
                <span>Valider</span><i class="fa-solid fa-arrow-right-long"></i>
              </button>

              <button
                v-if="edit_convention_lien"
                class="btn btn-primary ml-14"
                @click="edit_convention_lien = false"
              >
                <span>Annuler</span><i class="fa-solid"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- Abandon -->
            <div
              class="flexbox justifyCenter mt-40 mb-40 gap-2"
              v-if="demande_step.ordre > 1 && is_actif"
            >
              <button @click="to_abandon = true" class="btn btn-secondary">
                <span>La commune n'est plus interessée</span>
                <!-- <i class="fa-regular fa-arrow-right"></i> -->
              </button>
              <button
                v-if="demande_step.ordre > 3"
                @click="toRenouveler = true"
                class="btn btn-medium btn-primary"
              >
                <span>Renouveler</span>
              </button>

              <button
                v-if="demande_step.ordre > 3"
                @click="toProlonger = true"
                class="btn btn-medium btn-primary"
              >
                <span>Prolonger</span>
              </button>

              <custom-modal v-if="to_abandon" @close="to_abandon = false" @confirm="onAbandonner">
                <template v-slot:modalTitle>Abandon</template>
                <template v-slot:modalBody>
                  <custom-basic-select
                    label="Motif d'abandon : "
                    :options="optionsMotif"
                    v-model="motif_abandon"
                    ref="motif_abandon"
                    :rules="validations.motif_abandon"
                  />
                  <custom-input-type v-if="motif_abandon === '2'" v-model="autre_motif" />
                </template>
                <template v-slot:closeBtn>Annuler</template>
                <template v-slot:confirmationBtn
                  >Suivant <i class="fa-solid fa-arrow-right-long"></i
                ></template>
              </custom-modal>
              <custom-modal
                v-if="toRenouveler"
                @close="toRenouveler = false"
                @confirm="onOuiRenouvellerConvention"
                @cancel="onNonRenouvellerConvention"
                :hasCloseBtn="false"
                :hasCancelBtn="true"
              >
                <template v-slot:modalTitle
                  >Renouveler la convention de {{ has_communaute().nom_commune }}</template
                >
                <template v-slot:modalBody>
                  <div class="field">
                    <label>Souhaitez-vous refaire les supports de présentation ?</label>
                  </div>
                </template>
                <template v-slot:cancelBtn>Non</template>
                <template v-slot:confirmationBtn>Oui </template>
              </custom-modal>
              <custom-modal
                v-if="toProlonger"
                @close="toProlonger = false"
                @confirm="onProlongerConvention"
              >
                <template v-slot:modalTitle
                  >Prolonger la convention de {{ has_communaute().nom_commune }}</template
                >
                <template v-slot:modalBody>
                  <field-date
                    label="Validité de la convention"
                    v-model="date_fin_convention"
                    :class="field"
                    :name="'date_fin_convention'"
                    :min="$moment().format('YYYY-MM-DD')"
                    :rules="validations.date_fin_convention"
                  />
                </template>
                <template v-slot:closeBtn>Annuler</template>
                <template v-slot:confirmationBtn
                  ><span>Suivant</span><i class="fa-solid fa-arrow-right-long"></i
                ></template>
              </custom-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">La demande n'existe pas ou elle est inactive</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FieldDropzoneFile from "../../../components/customize/FieldDropzoneFile.vue";
import DemandeInfos from "./DemandeInfos.vue";
import DemandeListeCommunes from "./DemandeListeCommunes.vue";
import { WORKFLOW_STEPS } from "../../../../config/Properties";
import {
  DEMANDE_STATUT,
  DEMANDE_STATUT_CLE,
} from "../../../../../mutuelle-communale-api/app/configs/Properties";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
import CustomModal from "../../../components/customize/CustomModal.vue";
import CustomBasicSelect from "../../../components/customize/CustomBasicSelect.vue";
import { checkValidationRefs } from "../../../utils/validate";
import FieldDate from "../../../components/customize/FieldDate.vue";

export default {
  components: {
    DemandeInfos,
    FieldDropzoneFile,
    CustomInputType,
    CustomModal,
    CustomBasicSelect,
    FieldDate,
    DemandeListeCommunes,
  },
  name: "DemandeWorkflow",
  data() {
    return {
      demande: null,
      communaute: null,
      convention_file: null,
      convention_lien: null,
      date_fin_convention: null,
      conventions: [],
      edit_convention: false,
      edit_convention_lien: false,
      to_abandon: false,
      toProlonger: false,
      toRenouveler: false,
      toRenouvelerConfirm: null,
      statuts: DEMANDE_STATUT_CLE,
      motif_abandon: null,
      autre_motif: null,
      outputFile: "convention.docx",
      urlOutput: "#",
      validations: {
        convention: {
          required: "Merci de charger la convention",
          accept: {
            message: "Les formats autorisés sont que de pdf",
            extensions: ["pdf", "docx"],
          },
        },
        "date_fin_convention.date_fin_convention": {
          required: "Merci de saisir une date valide",
        },
        date_fin_convention: {
          required: "Merci de saisir une date valide",
        },
        date: {
          required: "Merci de saisir une date valide",
        },
        convention_lien: { required: "Merci de renseigner le lien de la convention" },
        motif_abandon: { required: "Merci de selectionner le motif" },
      },
    };
  },
  computed: {
    ...mapGetters(["isUserLogged"]),
    id_demande() {
      return this.$route.params.id;
    },
    steps_workflow() {
      return WORKFLOW_STEPS;
    },
    demande_step() {
      return WORKFLOW_STEPS[DEMANDE_STATUT[this.has_communaute()?.statut].step_workflow];
    },
    convention_path() {
      return (
        this.has_communaute()?.convention_file_path &&
        `${process.env.VUE_APP_API_URL}/uploads/${this.has_communaute()?.convention_file_path}`
      );
    },

    support_presentation_path() {
      return `${process.env.VUE_APP_API_URL}/uploads/SupportPresentationAdministres.pptx`;
    },

    is_actif() {
      return this.has_communaute()?.statut !== DEMANDE_STATUT_CLE.INACTIF;
    },
    optionsMotif() {
      return {
        0: "Choix pour un autre concurrent",
        1: "Abandon du projet",
        2: "Autre",
      };
    },
  },

  methods: {
    ...mapActions([
      "getDemande",
      "getCommunaute",
      "setLoading",
      "setError",
      "updateDemande",
      "getConvention",
      "uploadConvention",
      "sendNotifRenouvellement",
    ]),
    has_communaute() {
      return this.communaute ? this.communaute : this.demande;
    },
    old_convention_path(fileName) {
      return `${process.env.VUE_APP_API_URL}/uploads/${fileName}`;
    },
    onGetConvention() {
      return this.setLoading(true)
        .then(() => this.getConvention(this.has_communaute()))
        .then(response => {
          return (this.urlOutput = window.URL.createObjectURL(new Blob([response.data])));
        })
        .then(() => this.$refs.resultatConvention.click())
        .then(() =>
          this.updateDemande({
            id_demande: this.has_communaute()?.id_demande,
            statut: DEMANDE_STATUT_CLE.SIGNATURE,
            date_presentation: this.has_communaute()?.date_rdv,
          }),
        )
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => {
          alert(e);
          return this.setError(e);
        })
        .then(() => this.setLoading(false));
    },
    onAbandonner() {
      let motif =
        this.motif_abandon === "2" && this.autre_motif
          ? `Autre (${this.autre_motif})`
          : this.optionsMotif[this.motif_abandon];
      return this.setLoading(true)
        .then(() => {
          this.to_abandon = false;
          return this.updateDemande({
            id_demande: this.has_communaute()?.id_demande,
            statut: DEMANDE_STATUT_CLE.INACTIF,
            motif_abandon: motif,
            date_abandon: this.$moment().toJSON(),
            convention: {
              convention_file_path: this.demande.convention_file_path,
              convention_lien: this.demande.convention_lien,
              date_convention: this.demande.date_convention || this.demande.date_depot_convention,
            },
            date_fin_convention: null,
            convention_lien: null,
            convention_file_path: null,
            is_renouveler:
              this.demande.statut === "A_VALIDER" ||
              this.demande.statut === "A_RENOUVELER" ||
              this.demande.statut === "ACTIF"
                ? false
                : null,
          });
        })
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onEditConventionLien() {
      this.edit_convention_lien = true;
      this.convention_lien = this.has_communaute()?.convention_lien;
    },
    onProlongerConvention() {
      return this.updateDemande({
        id_demande: this.has_communaute()?.id_demande,
        statut: DEMANDE_STATUT_CLE.ACTIF,
        date_fin_convention: this.$moment(this.date_fin_convention.date_fin_convention).toDate(),
        is_renouveler: true,
        without_notification: true,
      })
        .then(() => this.sendNotifRenouvellement(this.id_demande))
        .then(() => this.getDemande(this.id_demande))
        .then(demande => {
          this.demande = demande;
          if (demande?.nom_communaute) {
            return this.getCommunaute(demande?.nom_communaute);
          }
        })
        .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
        .then(() => {
          this.toProlonger = false;
        });
    },
    onOuiRenouvellerConvention() {
      return this.updateDemande({
        id_demande: this.has_communaute()?.id_demande,
        statut: DEMANDE_STATUT_CLE.NOUVEAU,
        date_presentation: null,
        date_fin_convention: null,
        date_dossier: null,
        date_convention: null,
        date_abandon: null,
        date_depot_convention: null,
        is_renouveler: true,
        date_rdv: null,
        commentaire: null,
        motif_abandon: null,
        convention: {
          convention_file_path: this.has_communaute().convention_file_path,
          convention_lien: this.has_communaute().convention_lien,
          date_convention: this.has_communaute().date_convention || this.has_communaute().date_depot_convention,
        },
      })
        .then(() => {
          return window.open(process.env.SNOW_URL, "_blank");
        })
        .then(() => this.getDemande(this.id_demande))
        .then(demande => {
          this.demande = demande;
          if (demande?.nom_communaute) {
            return this.getCommunaute(demande?.nom_communaute);
          }
        })
        .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
        .then(() => {
          this.toRenouveler = false;
        });
    },
    onNonRenouvellerConvention() {
      return this.updateDemande({
        id_demande: this.has_communaute()?.id_demande,
        statut: DEMANDE_STATUT_CLE.PRESENTATION,
        date_presentation: null,
        date_fin_convention: null,
        date_convention: null,
        date_abandon: null,
        date_depot_convention: null,
        is_renouveler: true,
        motif_abandon: null,
        convention: {
          convention_file_path: this.demande.convention_file_path,
          convention_lien: this.demande.convention_lien,
          date_convention: this.demande.date_convention || this.demande.date_depot_convention,
        },
      })
        .then(() => this.getDemande(this.id_demande))
        .then(demande => {
          this.demande = demande;
          if (demande?.nom_communaute) {
            return this.getCommunaute(demande?.nom_communaute);
          }
        })
        .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
        .then(() => {
          this.toRenouveler = false;
        });
    },
    onUpdateConvention() {
      if (!checkValidationRefs([this.$refs.convention_file])) return window.scroll(0, 0);
      this.edit_convention = false;
      return this.setLoading(true)
        .then(() => {
          return this.uploadConvention({
            id_demande: this.has_communaute()?.id_demande,
            date_fin_convention: this.date_fin_convention?.date_fin_convention,
            convention_file: this.convention_file,
            date_convention: this.$moment().toDate(),
            statut:
              this.has_communaute()?.statut === "A_RENOUVELER" &&
              $moment()
                .add(2, "months")
                .isBefore(this.$moment(this.date_fin_convention?.date_fin_convention))
                ? "ACTIF"
                : this.has_communaute()?.statut,
          });
        })
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onValiderConvention() {
      if (!checkValidationRefs([this.$refs.convention_lien])) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() =>
          this.updateDemande({
            id_demande: this.has_communaute()?.id_demande,
            convention_lien: this.convention_lien,
            statut: DEMANDE_STATUT_CLE.ACTIF,
            date_convention: this.$moment().toJSON(),
          }),
        )
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    submitNewConventionLien() {
      if (!checkValidationRefs([this.$refs.convention_lien])) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() =>
          this.updateDemande({
            id_demande: this.has_communaute()?.id_demande,
            convention_lien: this.convention_lien,
            without_notification: true,
          }),
        ) // On met à jour la demande via l'API
        .then(() => this.getDemande(this.id_demande))
        .then(demande => {
          this.demande = demande;
          if (demande?.nom_communaute) {
            return this.getCommunaute(demande?.nom_communaute);
          }
        })
        .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
        .catch(e => this.setError(e))
        .then(() => {
          this.edit_convention_lien = false;
          this.setLoading(false);
        });
    },
    is_statut(s) {
      return this.has_communaute()?.statut === DEMANDE_STATUT_CLE[s];
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.getDemande(this.id_demande))
      .then(demande => {
        this.demande = demande;
        if (demande?.nom_communaute) {
          return this.getCommunaute(demande?.nom_communaute);
        }
      })
      .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
      .catch(e => this.setError(e))
      .then(() => this.setLoading(false));
  },
  watch: {
    motif_abandon: {
      handler(val) {
        if (val !== "2") this.autre_motif = null;
      },
    },
    id_demande: function (newQuestion, oldQuestion) {
      return this.setLoading(true)
        .then(() => this.getDemande(this.id_demande))
        .then(demande => {
          this.demande = demande;
          if (demande?.nom_communaute) {
            return this.getCommunaute(demande?.nom_communaute);
          }
        })
        .then(communaute => (this.communaute = communaute ? communaute?.[0] : null))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
  },
};
</script>
