<template>
  <ul class="data-list" v-if="hasInfos">
    <!-- ticket : #7995 -->
    <!-- <li>
      <span>Client Groupama : </span> <b>{{ demande.idgrc }}</b>
    </li> -->
    <li class="data" v-if="communaute">
      <span class="label-data">Lié à la communauté de communes : </span>
      <span class="answer-data">{{ communaute.nom_commune }} </span>
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Departements.Commune.Workflow', params: { id: communaute?.id_demande } }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>
    </li>
    <li class="data" v-if="communaute ? communaute?.date_rdv : demande.date_rdv">
      <span class="label-data">Rendez-vous prévu le : </span>
      <span class="answer-data">{{
        $moment(communaute ? communaute?.date_rdv : demande.date_rdv).format("DD/MM/YYYY")
      }}</span>
    </li>
    <li class="data">
      <span class="label-data"> RDS : </span>
      <span class="answer-data">
        <span>
          {{ communaute ? communaute?.rds_nom : demande.rds_nom }}
          <a
            v-if="communaute ? communaute?.rds_email : demande.rds_email"
            :href="`mailto:${communaute ? communaute?.rds_email : demande.rds_email}`"
          >
            <i class="fa-light fa-envelope"></i>
          </a>
        </span>
      </span>
    </li>
    <li class="data">
      <span class="label-data">Chargé d'affaires collectivités : </span>
      <span class="answer-data">
        <span>
          {{ communaute ? communaute?.charge_affaires_nom :demande.charge_affaires_nom }}
          <a v-if="communaute ? communaute?.charge_affaires_email : demande.charge_affaires_email" :href="`mailto:${communaute ? communaute?.charge_affaires_email :demande.charge_affaires_email}`">
            <i class="fa-light fa-envelope"></i>
          </a>
        </span>
      </span>
    </li>
    <li class="data">
      <span class="label-data">Agence : </span>
      <span class="answer-data">
        <span v-for="(line, i) in demandeInfos('agence')" :key="i">{{ line }}</span></span
      >
    </li>
    <li class="data">
      <span class="label-data">Candidature : </span>
      <span class="answer-data">{{ demande.candidature }}</span>
    </li>
    <li class="data">
      <span class="label-data">Commentaire : </span>
      <span class="answer-data">
        <span v-for="(line, i) in demandeInfos('commentaire')" :key="i">{{ line }}</span>
      </span>
    </li>
  </ul>
  <div v-else>En cours</div>
</template>

<script>
export default {
  name: "DemandeInfos",

  props: {
    demande: {
      type: Object,
      default: () => {},
    },
    communaute: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasInfos() {
      // > 5 car l'initialisation d'une demande affecte 5 props par défaut.
      return Object.values(this.demande).filter(v => v).length > 5;
    },
  },
  methods: {
    demandeInfos(p) {
      return this.communaute ? this.communaute?.[p]?.split("\n") || "" : this.demande[p]?.split("\n") || "";
    },
  },
};
</script>
