<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span v-if="validated && errors" class="error-message">{{ errors }}</span>
      </div>
      <v-select
        :multiple="multiple"
        :name="label"
        :clearable="true"
        :options="current_options"
        :placeholder="placeholder"
        :model-value="modelValue"
        :reduce="option => option.id"
        @update:modelValue="changeValue"
        @input="changeValue"
        @search="onSearch"
      >
        <template v-slot:no-options>Aucune option disponible</template>
      </v-select>
    </div>
    <label v-if="label"> {{ label }}</label>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { api } from "../../utils/api";
import { getError } from "../../utils/validate";
import { isString, isArray } from "lodash";
export default {
  emits: ["update:modelValue", "change"],
  components: {
    vSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    url_api: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: {},
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Date, Boolean, Array],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validated: false,
      current_options: [],
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    changeValue(o) {
      if (isString(o) || isArray(o)) {
        this.$emit("update:modelValue", o);
        this.$emit("change");
      }
    },
    validate() {
      this.validated = true;
    },
    onSearch(search, loading, spinner) {
      if (this.url_api && search) {
        this.current_options = [{ id: search, label: search }];
        loading(true);
        if (this.url_api) {
          api.get(`${process.env.VUE_APP_API_URL}/${this.url_api}/${search}`).then(res => {
            this.current_options.push(
              ...res.data.map(d => ({ id: d.nom || d.nom_commune, label: d.nom || d.nom_commune })),
            );
          });
        } else {
          this.current_options = this.$lodash.map(this.options, option => {
            if (option.label.includes(search)) return { id: option.value, label: option.label };
          });
        }
        loading(false);
      }
    },
  },
  watch: {
    options: {
      handler(val) {
        this.current_options = val.map(option => ({
          id: option.value,
          label: option.label,
        }));
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.url_api) {
      this.current_options = this.options.map(option => ({
        id: option.value,
        label: option.label,
      }));
    }
  },
};
</script>
<style scoped></style>
