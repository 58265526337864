<template>
  <div>
    <!-- Modale Nouvelle demande -->
    <custom-modal @close="reset_demande" @confirm="add_demande">
      <template v-slot:modalBody>
        <div>
          <custom-select-autocomplet
            label="Votre demande concerne quelle commune ? *"
            v-model="demande.nom_commune"
            url_api="communes/search"
            placeholder="Saisir le nom recherché"
            ref="demande.nom_commune"
            :rules="validations.commune"
          />
        </div>
        <div class="d-flex justify-content-center mt-14 mb-20">
          <input type="checkbox" v-model="demande.is_communaute" @click="toggleCheckbox" />
          <label @click="toggleCheckbox">Communauté de communes</label>
        </div>
        <div>
          <custom-select
            v-if="!demande.is_communaute"
            label="Souhaitez-vous la lier à une communauté de communes ?"
            v-model="demande.nom_communaute"
            placeholder="Communauté de commune"
            ref="demande.nom_communaute"
            :options="communautesOptions()"
          />
        </div>
      </template>
      <template v-slot:closeBtn>Annuler</template>
      <template v-slot:confirmationBtn
        ><span>Suivant</span><i class="fa-regular fa-arrow-right"></i
      ></template>
    </custom-modal>

    <custom-modal
      v-if="show_active_modal"
      @close="show_active_modal = false"
      @confirm="onConfirmActive"
    >
      <template v-slot:modalBody>Une demande active existe déjà pour cette commune</template>

      <template v-slot:closeBtn>Annuler</template>
      <template v-slot:confirmationBtn>
        <span>Voir le détail de la demande</span><i class="fa-regular fa-arrow-right"></i
      ></template>
    </custom-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { checkValidationRefs } from "../../../utils/validate";
import CustomModal from "../../../components/customize/CustomModal.vue";
import CustomSelectAutocomplet from "../../../components/customize/CustomSelectAutocomplet.vue";
import CustomSelect from "../../../components/customize/CustomBasicSelect.vue";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
export default {
  components: {
    CustomModal,
    CustomSelectAutocomplet,
    CustomSelect,
    CustomInputType,
  },
  inheritAttrs: false,
  name: "AddDemande",
  data() {
    return {
      formValid: true,
      demande: {
        nom_commune: null,
        nom_communaute: null,
        is_communaute: false,
      },
      show_active_modal: false,
      id_active_demande: null,
      communautes: [],
    };
  },
  computed: {
    validations() {
      return {
        commune: { required: "Merci de renseigner le nom de la commune." },
      };
    },
    codesOptions() {
      let options = {};
      this.codes_departements.map(c => (options[c] = c));
      return options;
    },
  },
  methods: {
    ...mapActions(["setLoading", "addDemande", "setError", "getActiveDemande", "getCommunautes"]),
    reset_demande() {
      Object.keys(this.demande).forEach(key => {
        this.demande[key] = null;
      });
      this.$parent.new_demande = false;
    },
    toggleCheckbox() {
      this.demande.is_communaute = !this.demande.is_communaute;
      this.demande.nom_communaute = null;
    },
    add_demande() {
      if (!checkValidationRefs(this.$refs)) return window.scroll(0, 0);
      let newDemande = false;
      return this.setLoading(true)
        .then(() => this.getActiveDemande(this.demande.nom_commune))
        .then(demande => {
          if (!demande) {
            newDemande = true;
            this.$parent.new_demande = false;
            if (this.demande.nom_communaute) {
              this.demande.nom_communaute = this.communautesOptions()[this.demande.nom_communaute];
            }
            return this.addDemande(this.demande);
          } else {
            newDemande = false;
            this.show_active_modal = true;
            this.id_active_demande = demande.id_demande;
            return newDemande;
          }
        })
        .then(() => {
          if (newDemande) window.open(process.env.SNOW_URL, "_blank");
        })
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    communautesOptions() {
      return this.communautes.map(comcom => {
        return comcom.nom_commune;
      });
    },
    setCommunautes() {
      return this.setLoading(true)
        .then(() => this.getCommunautes(""))
        .then(res => (this.communautes = res))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onConfirmActive() {
      return this.$router.push({
        name: "Departements.Commune.Workflow",
        params: { id: this.id_active_demande },
      });
    },
  },
  mounted() {
    return this.setCommunautes();
  },
};
</script>

<style></style>
