import axios from "axios";
import moment from "moment";
import { api, CustomError } from "../../utils/api";
import { downloadCSV } from "../../utils/globalsMethods";
import { APPEND_LIST_DEMANDES, SET_LIST_DEMANDES, SLICE_LIST_DEMANDES } from "../mutations_types";
export const demandeBaseUrl = "/demandes";
export const uploadsBaseUrl = "/uploads";

export const mutations = {
  [SET_LIST_DEMANDES]: (state, data) => {
    state.globals.demandesListe = data;
  },
  [APPEND_LIST_DEMANDES]: (state, data) => {
    state.globals.demandesListe.push(data);
  },
  [SLICE_LIST_DEMANDES]: (state, data) => {
    state.globals.demandesListe = state.globals.demandesListe.filter(
      c => c.id_demande !== data.id_demande,
    );
  },
};
export const actions = {
  addDemande({ commit }, data) {
    return api
      .post(`${demandeBaseUrl}`, data)
      .then(res => {
        commit(APPEND_LIST_DEMANDES, res.data);
      })
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },

  deleteDemande({ commit }, id) {
    return api
      .delete(`${demandeBaseUrl}/${id}`)
      .then(res => commit(SLICE_LIST_DEMANDES, { id_demande: id }))
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  updateDemande({ commit }, data) {
    let id = data.id_demande;
    delete data.id_demande;
    return api
      .post(`${demandeBaseUrl}/${id}`, data)

      .catch(e => {
        console.log(e.response);
        let infos = e?.response?.data;
        throw new CustomError({ ...infos });
      });
  },
  exporterDemandes({ commit }, data) {
    data = JSON.parse(JSON.stringify(data));
    let demandes = data.demandes.map(d => {
      d.date_creation = d.date_creation ? moment(d.date_creation).format("DD/MM/YYYY") : "";
      d.date_rdv = d.date_rdv ? moment(d.date_rdv).format("DD/MM/YYYY") : "";
      return d;
    });
    return Promise.resolve()
      .then(() => {
        return downloadCSV({
          obj: demandes,
          fields: [
            "nom_commune",
            "date_creation",
            "date_rdv",
            "rds_nom",
            "secteur",
            "snow_reference",
            "statut",
          ],
        });
      })
      .catch(e => {
        throw new CustomError({ message: e.toString() });
      });
  },
  getDemande({ commit }, id) {
    return api
      .get(`${demandeBaseUrl}/${id}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },

  getCommunaute({ commit }, nom) {
    return api
      .get(`${demandeBaseUrl}/communaute/${nom}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  getCommunautes({ commit },search) {
    return api
      .get(`${demandeBaseUrl}/communautes/search/all`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  getDemandesByCommunaute({ commit }, nom) {
    return api
      .get(`${demandeBaseUrl}/byCommunaute/${nom}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  getActiveDemande({ commit }, nomCommune) {
    return api
      .get(`${demandeBaseUrl}/active/${nomCommune}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },

  getConvention({ commit }, data) {
    let formData = new FormData();
    formData.append("Ville", data.nom_commune);
    return api
      .get(`${uploadsBaseUrl}/convention.docx`, { responseType: "blob" })
      .then(res => {
        formData.append("WordDoc", res.data);
        return formData;
      })
      .then(() =>
        axios.post(`${process.env.VUE_APP_ASPOSE_URL}/WordMailMerge?toformat=Docx`, formData, {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
      )
      .then(r => r)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  uploadConvention({ commit }, data) {
    let formData = new FormData();
    formData.append("convention", data.convention_file);
    const dateFormatted = new Date(data.date_fin_convention).toISOString(); // ISO 8601
    formData.append("date_fin_convention", dateFormatted);
    console.log(formData);
    return Promise.resolve()
      .then(() =>
        api.post(`${demandeBaseUrl}/convention/${data.id_demande}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
      )
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  uploadDemandesCommunes({ commit }, data) {
    let formData = new FormData();
    formData.append("file_maj", data.file);
    return api
      .post(`${demandeBaseUrl}/maj/${data.type_maj}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  getLastMaj({ commit }, type) {
    return api
      .get(`${demandeBaseUrl}/maj/${type}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  sendNotifRenouvellement({ commit }, demandeId) {
    return api
      .get(`${demandeBaseUrl}/sendNotifRenouvellement/${demandeId}`)
      .then(res => res.data)
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
};
export const getters = {
  demandesListe: state => {
    return state.globals.demandesListe;
  },
};
